<style scoped>


.form-input >>> .error--text {
  color: #AC2B37 !important;
}
.form-input >>> input {
  caret-color: #AC2B37 !important;
}
</style>
<style lang="sass">
  
  .v-textarea textarea
    padding: 10px!important
  
</style>
<template lang="pug">
div(v-if='Inicio').secundario
  v-divider.secundario.divider_inicial
  v-row.secundario.mb-12
  v-container(v-if='Inicio' fluid).secundario
    v-form.my-5
      v-row(v-scrollanimation titulo='true').my-12.d-none.d-md-block
        v-container
          v-row(align='center' justify='center')
            h1(v-html='Inicio.texto_contacto.titulo').text-center.font-weight-light.white--text
          v-row(align='center' justify='center').mt-5
            p(v-html='Inicio.texto_contacto.sub' style='line-height : 5px').text-center.font-weight-light.white--text.d-none.d-md-block
          v-row(align='center' justify='center')
            h2(v-html='Inicio.texto_contacto.sub' style='line-height : 10px').text-center.font-weight-light.d-md-none.white--text

      v-form(v-model='validación' autocomplete='false').pa-5.mt-12.d-none.d-md-block
        v-row(justify='center' align='start').fill-height
          v-col(cols=12 sm=5  v-scrollanimation columna='izquierda') 
            v-text-field(dark class='form-input' color='white' :rules='[Reglas.requerido, Reglas.solo_letras]' v-model='información["Nombre: "]' label='NOMBRE Y APELLIDOS:' )
            v-text-field(dark class='form-input' color='white' :rules='[Reglas.requerido, Reglas.email_valido]' v-model='información["Correo electrónico: "]'  label='E-MAIL:'  )
            v-text-field(dark class='form-input' color='white' :rules='[Reglas.requerido, Reglas.solo_números]'  v-model='información["Teléfono: "]' label='TELÉFONO:')
          //-pc
          v-col( sm=4 v-scrollanimation columna='derecha').d-none.d-md-block.ml-12    
            v-row
              v-textarea(class='form-input' :rules='[Reglas.requerido]' background-color="white"  placeholder='Mensaje' v-model='información.Mensaje' )
        v-row(align='center' justify='center' v-scrollanimation boton='true').mb-12.mt-12
            v-hover(v-slot="{ hover }").mt-12
              v-btn(v-if='hover' :dark='validación' @click='enviar()' :disabled='!validación'
              :loading='enviando_correo' style='transform: scale(1.1);').forma-boton.elevation-0.rojo Enviar
              v-btn(v-else :dark='validación' @click='enviar()' :disabled='!validación'
              :loading='enviando_correo').forma-boton.elevation-0.primario Enviar
      //-Móvil
      v-row(v-scrollanimation titulo='true').mt-5.mb-3.d-md-none
        v-container
          v-row(align='center' justify='center')
            h2(v-html='Inicio.texto_contacto.titulo').text-center.font-weight-light.white--text
          v-row(align='center' justify='center').mt-5
            p(v-html='Inicio.texto_contacto.sub' style='line-height : 5px').text-center.font-weight-light.white--text.d-none.d-md-block
          v-row(align='center' justify='center')
            h2(v-html='Inicio.texto_contacto.sub' style='line-height : 25px').text-center.font-weight-light.d-md-none.white--text.pa-3

      v-form(v-model='validación' autocomplete='false').pr-3.pl-3.d-md-none
        v-row(justify='center' align='start').fill-height
          v-col(cols=12 sm=5 v-scrollanimation columna='izquierda') 
            v-text-field(class='form-input' dark color='white' :rules='[Reglas.requerido, Reglas.solo_letras]' v-model='información["Nombre: "]' label='Nombre Y Apellidos:' )
            v-text-field(class='form-input' dark color='white' :rules='[Reglas.requerido, Reglas.email_valido]' v-model='información["Correo electrónico: "]'  label='E-mail:'   )
            v-text-field(class='form-input' dark  color='white' :rules='[Reglas.requerido, Reglas.solo_números]'  v-model='información["Teléfono: "]' label='Teléfono:' )
          v-col( sm=4 v-scrollanimation columna='derecha').d-none.d-md-block.ml-12    
            v-row
              v-textarea(background-color="white" placeholder='Mensaje' v-model='información.Mensaje' class='form-input' :rules='[Reglas.requerido]')
            v-row(align='center' justify='center')
              v-col.pl-0
                v-btn(color='primario' style='border-radius: 0px' dark block) Enviar
              v-spacer
          v-col(cols=12 v-scrollanimation columna='derecha').justify-center.aling-center      
            v-textarea(background-color="white" placeholder='Mensaje' v-model='información.Mensaje' :rules='[Reglas.requerido]' class='form-input')
            p(v-html='Inicio.texto_contacto.text_box'   color='primario ').text-center
    

        v-row(justify='space-around' v-scrollanimation boton='true').d-none.d-md-flex 
          v-hover(v-slot="{ hover }")
            v-btn(color='red' :dark='hover'
             :disabled='!validación || información["Forma de contacto"].length == 0' 
             @click='enviar()'
             ).elevation-0
              span(v-if='hover' v-html='Inicio.texto_contacto.btn_enviar' ).text-center.white--text.mt-3.body-1
              span(v-else v-html='Inicio.texto_contacto.btn_enviar' ).text-center.black--text.mt-3.body-1
        v-row(justify='center' v-scrollanimation boton='true').d-md-none.mb-12
          v-container
            v-btn(x-large block color="primario" :dark='validación' :disabled='!validación'
            style='border-radius: 25px !important' :loading='enviando_correo'
            @click='enviar()').elevation-0
              h2.text-center.white--text.body-1 Enviar

</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data: ()=>({
    información: {
      "Forma de contacto": [],
    },
    validación: false,
    enviando_correo: false
  }),
  computed: {
    ...mapState({
      Reglas: ({Ayudas})=>Ayudas.reglas,
    }),
  },
  methods:{
    ...mapActions({
      enviar_correo_electronico: 'Contacto/enviar_correo_electronico',
      activar_snackbar: 'Ayudas/activar_snackbar',
    }),
    enviar(){
      const titulo = 'Información del contacto'
      const asunto = `Nuevo contacto`
      let cuerpo = `
      <table>`
      Object.keys(this.información).map( i => cuerpo+='<tr><td>' + i + '</td><td>' + this.información[i] + '</td></tr>')
      cuerpo += `</table>`
      const { configuracion_de_correo, correo_electronico} = this.Contacto
      this.enviando_correo = true
      console.log(configuracion_de_correo)
      this.enviar_correo_electronico({titulo, cuerpo, asunto, correo_electronico, configuracion_de_correo})
      .then( () => {
        this.activar_snackbar('Se ha enviado su mensaje. Nos pondremos en contacto lo más pronto posible.')
        this.enviando_correo = false
        this.información  = {
          "Forma de contacto": [],
        }
      })
    },
  },
  props:{
    fondo_claro: Boolean
  }
}
</script>
<style lang="sass" scoped>
.forma-boton
  transition-duration: .4s
  width: 120px
  height: 87px
  border-radius: 25px

.before-enter
  opacity: 0
  transition: all 1.5s ease-out
  &[columna='derecha']
    transform: translateX(100%)
  &[columna='izquierda']
    transform: translateX(-100%)
  &[titulo='true']
    transform: translateY(100px)
  &[boton='true']
    transform: scale(0.1, 0.1)
    transition: transform 2s ease
.enter
  opacity: 1
  &[columna='derecha']
    transform: translateX(0%)
  &[columna='izquierda']
    transform: translateX(0%)
  &[titulo='true']
    transform: translateY(0)
  &[boton='true']
    transform: scale(1, 1)
</style>