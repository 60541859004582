import { render, staticRenderFns } from "./contenedor_contacto.vue?vue&type=template&id=3938c662&scoped=true&lang=pug&"
import script from "./contenedor_contacto.vue?vue&type=script&lang=js&"
export * from "./contenedor_contacto.vue?vue&type=script&lang=js&"
import style0 from "./contenedor_contacto.vue?vue&type=style&index=0&id=3938c662&scoped=true&lang=css&"
import style1 from "./contenedor_contacto.vue?vue&type=style&index=1&lang=sass&"
import style2 from "./contenedor_contacto.vue?vue&type=style&index=2&id=3938c662&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3938c662",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VHover } from 'vuetify/lib/components/VHover';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCol,VContainer,VDivider,VForm,VHover,VRow,VSpacer,VTextField,VTextarea})
